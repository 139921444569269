/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
       url('./assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* poppins-500 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
       url('./assets/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
       url('./assets/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* poppins-700 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
       url('./assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
    font-display: swap;
}

body {
    color: #153b50 !important;
    width: 100%;
}
.bm-overlay {
    left: 0;
    top: 0
}
.listContainer {
    display: flex;
    width: 100%;
}

.mainContainer {
    width: 100%;
    min-height: 800px;
    background: #f7f9fc;
    padding: 0 15px 0 15px;

    .mainContainerTitle {
        margin: 45px 0 20px 32px;
        font-size: 22px;
        line-height: 33px;
        font-family: 'Poppins', sans-serif;
        color: #152b50;
    }
}

.noDataFound {
    font-size: 20px;
    line-height: 33px;
    font-family: 'Poppins', sans-serif;
    color: #152b50;
}

button {
    height: 50px !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
}

button:hover {
    opacity: 0.7 !important;
}

.newButton {
    width: 255px;
    >button {
        display: flex;
        align-items: center;
        font-family: 'Poppins', sans-serif !important;
        border: 1.5px solid #00a0ff;
        font-size: 14px;
        line-height: 28px;
        color: #00a0ff;
        >span {
            margin-right: 4px;
        }
    }
}

.saveOrUpdateButton {
    width: 100px !important;
    color: white !important;
    background: rgba(0, 160, 255, 0.8) !important;
    font-size: 14px !important;
    line-height: 21px !important;
    font-family: 'Poppins', sans-serif !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
}
.saveOrUpdateButton:hover {
    @media (max-width: 810px) {
        opacity: 1 !important;
    }
}
.cancelButton {
    width: 100px !important;
    color: #153B50 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    background-color: white !important;
    border: 1px solid #c8c8c8 !important;
    font-family: 'Poppins', sans-serif !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
}

.deleteButton {
    width: 140px !important;
    background: #d6493333 !important;
    border: 1px solid #d6493333 !important;
    color: #D64933 !important;
    margin-right: 0px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
}

.deleteButton:hover {
    color: white !important;
}

.ui.button {
    font-weight: 400 !important;
}

.resultButton {
    width: 84px !important;
    height: 40px !important;
    background: rgba(21, 59, 80, 0.8) !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    color: white !important;
}

.resultButton:hover {
    background: rgba(21, 59, 80, 0.9);
    color: white;
}

input:focus,
textarea:focus,
select:focus {
    outline: none !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.tag {
    overflow: unset !important;
}

.required:before {
    content:" *";
    color: red;
    font-size: 20px;
    margin-right: 2px;
    margin-bottom: 4px;
}

.addressForm .required:before {
    content:" *";
    color: red;
    font-size: 20px;
    margin-left: -15px;
    margin-bottom: 4px;
}
.highcharts-point {
    cursor: pointer;
}
.highcharts-point:hover {
    outline: 2px solid rgb(108, 100, 149) !important;
}
.highcharts-point.heatmap-selected {
    outline: 2px solid white !important;
}
.highcharts-coloraxis-labels * {
    color: white !important;
    fill: white !important;
}
// .highcharts-reset-zoom {
//     // display: none;
// }